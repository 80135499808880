import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
// pages
import { Layout } from "./components/Layout.js";
import { Index } from "./pages/Index";
import { TOU } from "./pages/terms-of-use.js";
import { Privacy } from "./pages/privacy.js";

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route path="/" element={<Index />} />
          <Route path="/terms-of-use" element={<TOU />} />
          <Route path="/privacy" element={<Privacy />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRouter;