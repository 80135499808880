import { Container, Link, Button, Typography } from '@mui/material';
import LogoWhite from '../../assets/img/logo_white.svg';

export const Header = () => {

    return (
        <div style={{ minHeight: "120px", width: "100%" }}
        >
            <Container style={{ paddingTop: "40px" }}>
                <Link style={{ float: "left", display: "flex" }} href="/">
                    <img src={LogoWhite} alt="KoinDX logo" style={{ maxHeight: "36px", float: "left" }} />
                </Link>
                <div style={{ float: "right" }}>
                    <Button className={"click-on-app-header"} variant="outlined" size="large" href="https://app.koindx.com">
                        <Typography variant="button" component="span">LAUNCH APP</Typography>
                    </Button>
                </div>
            </Container>
        </div>
    )
}