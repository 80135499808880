import { useEffect, useState } from "react"
import { get as _get } from "lodash";
import { Container, Typography, Grid } from '@mui/material'
import { global } from "./../../service/GlobalController"

export const NumberSection = () => {
    const [tvl, setTvl] = useState("-")
    const [volumen, setVolumen] = useState("-")

    useEffect(() => {
        const load = async () => {
            try {
                let result = await global.getData();
                if(!result.errors && !result.error) {
                    let _tvl = _get(result, "totalLiquidityUSD", "-");
                    let _volume = _get(result, "totalVolumeUSD", "-");
                    setTvl(_tvl != "-" ? Math.floor(_tvl) : _tvl)
                    setVolumen(_volume != "-" ? Math.floor(_volume) : _volume)
                }
                setTimeout(() => load(), 10000)
            } catch (error) {
                console.log(error)
            }
        }
        load()
    }, [])

    return (
        <Container >
            <Grid container spacing={2} sx={{ paddingBottom: { xs: "0px", md: "150px" }, textTransform: "uppercase", width: "100%", marginX: "auto" }}>
                <Grid item xs={12} md={6} sx={{ textAlign: "center", marginY: 4, padding: "0px !important" }}>
                    <Typography variant="h2">
                        $ { volumen != "-" ? volumen.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "-" }
                    </Typography>
                    <Typography variant="h4">
                        TOTAL TRADING VOLUME
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: "center", marginY: 4, padding: "0px !important" }}>
                    <Typography variant="h2">
                        $ { tvl != "-" ? tvl.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "-" }
                    </Typography>
                    <Typography variant="h4">
                        TOTAL VALUE LOCKED
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}