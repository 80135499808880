import { Grid, Container, Box, Typography } from '@mui/material'
import Community from '../../assets/img/koindx-social-media-community-image.svg'
import { OutlinedButton } from '../OutlinedButton'
import { keyframes } from '@mui/material';

const spin = keyframes`
    0%, 100% {  
        transform: translateY(0em);
    }
    50% {
        transform: translateY(2em);
    }
`;

export const CommunitySection = () => {
    return (
        <Container sx={{ minHeight: "500px", width: "100%" }}>
            <Grid container spacing={12} sx={{ minHeight: "900px", paddingTop: "40px" }}>
                <Grid item xs={12} md={6}>
                    <Box sx={{
                    }}>
                        <Box sx={{ animation: `${spin} 3s infinite ease`, paddingTop: { sx: "50px", md: "0" } }}>
                            <img src={Community} alt="KoinDX community channels discord telegram and twitter floating around as speech bubbles" width="100%" />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: { xs: "center", md: "left" } }} >
                    <Typography sx={{ paddingTop: { xs: "0px", md: "50px" }, textTransform: "uppercase" }} variant="h2">Join our Community</Typography>
                    <Typography variant="body2" sx={{ padding: "25px 0px 30px 0px", fontWeight: 500, fontSize: "22px", color: "#848484" }}>Become our newest community member and be part of the most exciting project on Koinos! Do you like DeFi? Do you want to know which projects are launching on Koinos? KoinDX is the trading hub on this chain. Join us, learn more and get involved!</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <OutlinedButton target={"_blank"} label="Discord" href="https://discord.koindx.com" />
                        <OutlinedButton target={"_blank"} label="Twitter" href="https://twitter.com/koindx" />
                        <OutlinedButton target={"_blank"} label="Telegram" href="https://t.me/koindx" />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}