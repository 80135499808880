import { Button, Typography } from '@mui/material';

export const FilledButton = ({ label, href }) => {
    return (
        <Button href={href} size="large" variant="contained" sx={{ padding: "20px", marginTop: "30px"}} >
            <Typography variant="button" sx={{ fontWeight: 900, fontSize: "20px"}}>
                {label}
            </Typography>
        </Button>
    )
}