import { Typography, Container, Box } from "@mui/material"
import { OutlinedButton } from '../OutlinedButton'
import RRS from "../../assets/img/partner/Royal_Reptile_Studios_white_small.png"
import KG from "../../assets/img/partner/koinos_group_white.svg"
import PK from "../../assets/img/partner/planet_koinos_white.svg"
import KoinCity from "../../assets/img/partner/Koincity_text_white.png"
import { styled } from 'styled-components';

export const Partner = () => {
    return (
        <Container sx={{ marginTop: { xs: "150px", md: "0"}, textAlign: "center" } }>
            <Typography variant="h2" sx={{ textTransform: "uppercase", paddingBottom: "30px" }}>our<br />Partners</Typography>
            <Box sx={{ marginY: "3em", display: "flex", justifyContent: "space-between", alignItems: "center", flexFlow: { xs: "column", md: "row" } }}>
                <PartnerTile image={KG} imageH={230} alt={"Koinos Group"} link={"https://koinos.group"} title={"Visit Koinos Group"} />
                <PartnerTile image={RRS} imageH={160} alt={"Royal Reptile Studios"} />
                <PartnerTile image={PK} imageH={160} alt={"Planet Koinos"} link={"https://planetkoinos.com/"} title={"Visit Planet Koinos"} />
                <PartnerTile image={KoinCity} imageH={60} alt={"Koincity"} link={"https://koincity.com/"} title={"Visit Koincity"} />
            </Box>
            <OutlinedButton label="Become a partner" href="mailto:contact@koindx.com" />
        </Container>
    )
}

const PartnerImage = styled.img`
    filter: opacity(50%);
    &:hover {  filter: opacity(100%)
    } 
`

const PartnerTile = (props) => {
    const { image, alt, link = false, title, imageH = 200 } = props
    return (
        <Box sx={{ display: "block" }}>
            {link ?
                <a target={"_blank"} href={link} title={title} rel="noreferrer">
                    <PartnerImage src={image} alt={alt} height={imageH} style={{ objectFit: "contain" }} />
                </a> :
                <PartnerImage src={image} alt={alt} height={imageH} style={{ objectFit: "contain" }} />
            }
        </Box>
    )
}