import { Typography, Container, Button, Box } from "@mui/material"

export const Documentation = () => {
    return (
        <Container sx={{ marginTop: { xs: "100px", md: "250px" } }}>
            <Typography variant="h2" sx={{ textTransform: "uppercase", textAlign: "center" }}>Documentation</Typography>

            <Box align="center" marginX={"auto"} sx={{ marginTop: "60px", width: "100%", maxWidth: "660px", borderRadius: "10px", padding: "32px", backgroundColor: "background.paper" }}>
                <Typography variant="body2" sx={{ fontWeight: 500, fontSize: "22px", color: "#848484" }}>Read everything about the features and functions of KoinDX. Become a power user and understand how to use KoinDX for you.</Typography>
                <Button variant="outlined" size="large" sx={{ padding: "14px 24px", marginTop: "32px" }} href="https://docs.koindx.com" target={"_blank"}>
                    <Typography variant="button" component="span">Learn More</Typography></Button>
            </Box>

        </Container>
    )
}