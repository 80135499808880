
import { Outlet } from "react-router-dom";
import { Header } from './home/Header';
import { Footer } from './home/Footer';
import { useTheme } from "@mui/material";
export const Layout = () => {
    const theme = useTheme();
    return (
        <div style={{ backgroundColor: theme.palette.background.default }}>
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}