import { Container, Box, Typography } from "@mui/material";
import { FilledButton } from "../components/FilledButton";
// import { TokenSection } from "../components/home/TokenSection";
import { Documentation } from "../components/home/Documentation";
import { NumberSection } from "../components/home/NumberSection";
import { CommunitySection } from "../components/home/CommunitySection";
// import { Whaletracker } from "../components/home/Whaletracker";
import { Partner } from "../components/home/Partner";
import { Helmet } from "react-helmet";

export const Index = () => {
  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KoinDX</title>
        <link rel="canonical" href="https://koindx.com" />
        <meta name="description" content="KoinDX - The leading crypto trading protocol on Koinos blockchain." />
      </Helmet>

      <Box
        sx={{
          minHeight: { xs: "auto", md: "700px", lg: "800px" },
          marginTop: "-120px",
          paddingBottom: "50px",
        }}
      >
        <Container>
          <Box sx={{ paddingTop: { xs: "100px", md: "180px", xl: "300px" } }}>
            <Typography variant="h1" sx={{ textTransform: "uppercase", lineHeight: "1.25", fontSize: { xs: "62px", md: "96px" } }}>
              KOINDX IS LIVE
              <br /> 
              TRADE NOW
            </Typography>
          </Box>
          <Box sx={{ position: "relative", top: 0 }}>
            <FilledButton className={"click-on-main-button"} label="Launch app" href="https://app.koindx.com" />
          </Box>
        </Container>
      </Box>
      <NumberSection />
      {/* <TokenSection /> */}
      <CommunitySection />
      <Partner />
      <Documentation />
      {/* <Whaletracker /> */}
    </Box>
  );
};
/*


<Container>
          <Box sx={{ paddingTop: { xs: "100px", md: "180px", xl: "300px" } }}>
            <Typography variant="h1" sx={{ textTransform: "uppercase", lineHeight: "1.25", fontSize: { xs: "62px", md: "96px" } }}>
              LAUNCH KOINDX
              <br />
              AND EXPLORE DEFI
              <br />
              ON KOINOS
            </Typography>
          </Box>
          <Box sx={{ position: "relative", top: 0 }}>
            <FilledButton label="Launch App" href="https://testnet.koindx.com" />
          </Box>
        </Container>


 */